<template>
  <v-footer
    v-if="role != 'admin' && role != 'employee' && $route.name != 'carrito'"
    class="mt-1"
    color="white"
  >
    <v-row justify="center">
      <v-col cols="12" sm="12" md="8" class="pl-8 mt-8">
        <h3 class="text-uppercase">información de la tienda</h3>
        <p class="text_info mb-1">SIECOM</p>
        <p class="text_info mb-1">larga distancia no 70</p>
        <p class="text_info mb-1">col ampliación sinatel, 09479 Iztapalapa Ciudad de México</p>
        <p class="text_info mb-1">Llámenos: (55) 6721 42 13</p>
        <p class="text_info mb-1">Envíenos un mensaje de correo electrónico:</p>
        <a href="mailto:ventas@cicsis.mx" class="mail_to primary--text">
          ventas@siecomenlinea.mx
        </a>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="pl-8 mt-8">
        <div v-for="(page, i) in pages" :key="i">
          <router-link
            class="text-decoration-none"
            v-if="page.status"
            :to="`/page/${page.title}`"
          >
            {{ page.title }}
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" class="py-4 text-center primary--text">
        {{ new Date().getFullYear() }} <strong>SIECOM</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState("auth", ["user", "isLogged", "role"]),
    ...mapState("Pages", ["pages"]),
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions("Pages", ["getPages"]),

    initialize() {
      this.getPages(`?limit=20&status=true`);
    },
  },
};
</script>

<style scoped>
.text_info {
  font-size: 17px;
  color: #8c8c8c;
}
.mail_to {
  text-decoration: none;
  font-size: 17px;
}
</style>
