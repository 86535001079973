import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueAxios from "vue-axios";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import wysiwyg from "vue-wysiwyg";
// import JsonExcel from "vue-json-excel";
import VueHtml2pdf from "vue-html2pdf";
import VueApexCharts from "vue-apexcharts";
import VueCookies from "vue-cookies";
import { StripePlugin } from "@vue-stripe/vue-stripe";
import Meta from "vue-meta";
import VueHead from "vue-head";
import FlagIcon from "vue-flag-icon";
import "sweetalert2/dist/sweetalert2.min.css";
import "./index.css";

Vue.config.productionTip = false;
let baseUrl = process.env.VUE_APP_BASE_URL;

// axios.defaults.timeout = 25000;
axios.defaults.baseURL = baseUrl;

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
  apiVersion: process.env.VUE_APP_STRIPE_API_VERSION,
  testMode: true,
  locale: process.env.VUE_APP_STRIPE_LOCALE,
};

VueCookies.config("7d");
Vue.use(VueCookies);
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(FlagIcon);
Vue.use(VueHead, {
  separator: "-",
  complement: "Siecom",
});
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});
Vue.use(wysiwyg, {
  hideModules: {
    link: true,
    orderedList: true,
    unorderedList: true,
    table: true,
    code: true,
    image: true,
    separator: true,
  },
  maxHeight: "500px",
});
Vue.use(VueHtml2pdf);
Vue.use(StripePlugin, options);
// Vue.component("downloadExcel", JsonExcel);
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  axios,
  render: (h) => h(App),
}).$mount("#app");
