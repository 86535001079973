<template>
  <div>
    <div v-for="(item, i) in categories" :key="i">
      <a class="black--text" @click="navigate(item)">
        {{ item.nombre }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    category: Object
  },

  data: () => ({
    categories: []
  }),

  mounted() {
    if (this.id) {
      this.getSubCategories();
    }
  },

  methods: {
    async getSubCategories() {
      await this.$http
        .get(`/categories/?level=${3}&categoryId=${this.id}`)
        .then((result) => {
          const categories = result.data;
          if (result.data.lenght == 0) {
            this.categories = [];
          }
          this.categories = categories;
          // categories.forEach((item) => console.log(item.origen));
        })
        .catch((err) => {
          console.log(err);
        });
    },

    navigate(item) {
      const { origen } = item;
      let category = origen.find((cat) => cat.nivel == 2);
      let subcategory = item.nombre.replace(/ /g, '_').replace(/\//g, '&');
      this.$emit(
        'action',
        category.nombre.replace(/ /g, '_').replace(/\//g, '&'),
        subcategory
      );
    }
  }
};
</script>

<style></style>
