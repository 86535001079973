<template>
  <v-row>
    <v-col class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 pb-0">
      <v-select
        solo
        flat
        hide-details
        append-icon="mdi-chevron-down"
        prepend-inner-icon="mdi-filter"
        v-model="selectSearch"
        label="hola"
        :items="items"
        dense
      ></v-select>
    </v-col>
    <v-col class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pb-0">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Busqueda"
        single-line
        hide-details
        solo
        flat
        @keypress.native.enter="goSearch"
        @click:append="goSearch"
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    search: null,
    stock: 1,
    selectSearch: 'buscar por titulo',
    items: ['buscar por código', 'buscar por titulo'],
    loading: false,
    limit: 30,
    title: '',
    products: [],
    menu: false
  }),
  watch: {
    // search(val) {
    //   // let value = "";
    //   if (!val) {
    //     return;
    //   }
    //   if (this.selectSearch == "buscar por titulo") {
    //     value = `?title=${val}`;
    //   } else if (this.selectSearch == "buscar por código") {
    //     value = `?model=${val}`;
    //   } else if (val == null) {
    //     value = "";
    //   }
    //   this.$store.commit("setSearch", value);
    //   this.getProduct(val);
    // },
  },

  // created() {
  //   this.getCodes();
  // },

  methods: {
    async getCodes() {
      this.loading = true;

      await this.$http
        .get(`/products/codes?stock=${this.stock}`)
        .then((result) => (this.products = result.data))
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },

    async getProduct(id) {
      // ! Deprecated method due to product search change
      await this.$http
        .get(`products/${id}`)
        .then((result) => {
          const { categorias, _id, titulo } = result.data;
          const category = categorias.find((item) => item.nivel == 1);
          const subcategory = categorias.find((item) => item.nivel == 2);
          const catLvl3 = categorias.find((item) => item.nivel == 3);

          let title = '';
          for (let i = 0; i < 31; i++) {
            title = title + titulo[i];
          }

          let url = `/${category.nombre.replace(/\s+/g, '')}/${
            subcategory
              ? `${subcategory.nombre.replace(/\s+/g, '')}`
              : `${catLvl3.nombre.replace(/\s+/g, '')}`
          }/${title.replace(/\s+/g, '').replace(/\//g, '&')}/${_id}`;

          if (this.$route.path == url) {
            return;
          }

          this.$router.push(url);
          this.$emit('action', false);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    goSearch() {
      let search = null;

      if (this.selectSearch == 'buscar por titulo') {
        search = `?title=${this.search}`;
      } else if (this.selectSearch == 'buscar por código') {
        search = `?model=${this.search}`;
      }

      this.$store.commit('setSearch', search);

      if (this.$route.name !== 'search') {
        console.log(this.$route.name);
        this.$router.push(`/search`);
      }
    }
  }
};
</script>

<style></style>
