<template>
  <div>
    <v-navigation-drawer app v-model="drawer">
      <v-list nav dense>
        <div class="d-flex justify-center mb-3 mt-3 d-md-none">
          <a @click="goBack">
            <v-img
              src="../assets/logo.png"
              max-width="100"
              max-height="100"
              to="/"
            ></v-img>
          </a>
        </div>
        <template>
          <div class="text-center">
            <span v-if="isLogged">
              {{ user?.name ? user.name : '' }}
            </span>
          </div>

          <div v-if="!isLogged" class="pa-2">
            <v-btn
              class="elevation-0 d-flex d-md-none"
              color="primary"
              to="/login"
              block
            >
              Iniciar Sesion
            </v-btn>
          </div>
          <div v-else class="pa-2">
            <v-btn class="elevation-0" color="error" @click="logOut" block>
              Cerrar Sesion
            </v-btn>
          </div>
        </template>
        <v-row class="pa-2" v-if="role != 'admin' && role != 'employee'">
          <v-col class="text-center" cols="12" sm="12">
            <span style="font-size: 12px;">TC:${{ currency }}</span>
          </v-col>
          <v-col class="d-flex justify-center" cols="12" sm="12">
            <flag :iso="type ? 'mx' : 'us'" class="mr-2" />
            <v-switch
              class="ml-1"
              style="padding: 0; margin: 0;"
              v-model="type"
              hide-details
              inset
              @change="_changeType"
            ></v-switch>
          </v-col>
        </v-row>
        <v-list :value="true">
          <div v-for="(item, index) in items" :key="index">
            <v-list-item
              :to="item.to"
              link
              v-if="item.to !== '/users'"
              active-class="white--text primary"
              :value="true"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
            <v-list-group no-action :prepend-icon="item.icon" v-else>
              <template v-slot:activator>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </template>

              <v-list-item
                v-for="(inter, i) in item.items"
                :key="i"
                :to="inter.to"
                link
              >
                <v-list-item-title v-text="inter.text"></v-list-item-title>
                <v-list-item-icon>
                  <v-icon v-text="inter.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
        <!-- <v-list-item-group v-model="group" active-class="white--text primary">
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :to="item.to"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group> -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app style="height: auto !important;">
      <!-- -->
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <a @click="goBack" class="ma-2">
        <v-img
          class="d-none d-md-block"
          src="../assets/logo.png"
          max-width="100"
          max-height="100"
        ></v-img>
      </a>
      <v-spacer class="d-block d-md-none"></v-spacer>
      <a @click="goBack">
        <v-img
          class="d-block d-md-none"
          src="../assets/logo.png"
          max-width="100"
          max-height="100"
        ></v-img>
      </a>

      <v-spacer></v-spacer>
      <search
        class="d-none d-md-flex"
        v-if="role != 'admin' && role != 'employee'"
      />

      <v-menu
        v-if="isLogged"
        v-model="accountMenu"
        :close-on-content-click="false"
        :nudge-width="100"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item v-if="role != 'admin' && role != 'employee'">
              <v-btn class="elevation-0" color="primary" to="/my-orders" block>
                Mis Ordenes
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn class="elevation-0" color="error" @click="logOut" block>
                Cerrar Sesion
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <span
        v-if="role != 'admin' && role != 'employee'"
        style="font-size: 12px;"
        class="mr-1 d-none d-md-block"
        >TC:${{ currency }}</span
      >
      <flag
        v-if="role != 'admin' && role != 'employee'"
        :iso="type ? 'mx' : 'us'"
        class="mr-2 d-none d-md-block"
      />
      <v-switch
        class="d-none d-md-block"
        v-if="role != 'admin' && role != 'employee'"
        v-model="type"
        hide-details
        inset
        @change="_changeType"
      ></v-switch>

      <v-btn
        v-if="!isLogged"
        color="primary"
        class="elevation-0 d-none d-md-flex"
        to="/login"
      >
        iniciar sesion
      </v-btn>

      <v-btn
        v-if="role != 'admin' && role != 'employee'"
        class="d-flex d-md-none"
        icon
        @click="dialog = true"
        color="primary"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-badge
        class="elevation-0 ml-2 mr-2"
        color="primary"
        overlap
        :value="totalQuantity"
        :content="totalQuantity"
        v-if="role != 'admin' && role != 'employee'"
      >
        <v-icon @click="drawerCart = !drawerCart">mdi-cart</v-icon>
      </v-badge>

      <template v-slot:extension v-if="role != 'admin' && role != 'employee'">
        <v-tabs v-if="!isMenuPersonalized" center-active show-arrows>
          <v-tab
            v-for="(item, index) in categories"
            :key="index"
            @click="openDilaogCategory(item)"
          >
            {{ item.nombre }}
          </v-tab>
        </v-tabs>
        <v-tabs v-else center-active show-arrows>
          <v-tab
            v-for="(item, index) in menu"
            :key="index"
            @click="openDialogMenu(item)"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-if="role != 'admin' && role != 'employee'"
      app
      v-model="drawerCart"
      right
      temporary
    >
      <v-row>
        <v-col class="col-12 col-sm-12 col-md-12 ma-1">
          <v-btn icon right @click="drawerCart = !drawerCart">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-list three-line>
        <v-subheader>
          <v-btn
            v-if="cart.length >= 1"
            class="elevation-0"
            color="error"
            @click="removeCart"
            text
            block
          >
            <v-icon>mdi-delete</v-icon>
            Eliminar Productos
          </v-btn>
        </v-subheader>

        <template v-for="(item, index) in cart">
          <v-list-item :key="index">
            <v-list-item-content>
              <v-list-item-title v-html="item.titulo"></v-list-item-title>
              <v-list-item-subtitle>{{ item.modelo }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <div class="flex">
                <v-btn icon v-on:click="removeProduct(item)">
                  <v-icon color="error"> mdi-minus </v-icon>
                </v-btn>
                <a class="ma-0 pa-0 black--text">
                  {{ item.quantity }}
                </a>
                <v-btn icon v-on:click="addProduct(item)">
                  <v-icon color="success"> mdi-plus </v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" text block to="/carrito">
            <!-- Ver carrito -->
            Ver carrito
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dialog">
      <v-card class="pa-4">
        <search :dialog="dialog" @action="close" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCategory" max-width="1450">
      <v-card class="pa-4 card_content">
        <v-row>
          <v-col
            class="col-12 col-sm-12 col-md-3"
            v-for="(item, i) in categorySelected.subcategorias"
            :key="i"
          >
            <p
              class="pa-0 text-center primary--text"
              style="cursor: pointer;"
              @click="navigate(item)"
            >
              {{ item.nombre.substring(0, 60) + '..' }}
            </p>

            <SubCategory
              :category="item"
              v-if="item"
              :id="item.id"
              @action="navigate"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="menuDialog" max-width="900">
      <v-card class="pa-4">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
            v-for="(item, i) in itemMenu.subcategories"
            :key="i"
          >
            <p
              class="pa-0 text-center primary--text"
              style="cursor: pointer;"
              @click="goToCategory(item)"
            >
              {{ item.name.substring(0, 50) + '..' }}
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Search from './NavBar/Search.vue';
import SubCategory from './NavBar/SubCategory.vue';
export default {
  data: () => ({
    country: false,
    type: true,
    drawer: false,
    isMenuPersonalized: false,
    menuDialog: false,
    itemMenu: {},
    accountMenu: false,
    drawerCart: false,
    group: null,
    dialog: false,
    dialogCategory: false,
    categorySelected: {},
    // search: "",
    items: [
      { text: 'Estadísticas', icon: 'mdi-chart-box', to: '/stats' },
      { text: 'Menús sitio', icon: 'mdi-view-dashboard', to: '/menus' },
      // { text: "Facturas", icon: "mdi-file-document-multiple", to: "/bills" },
      {
        text: 'Ordenes online',
        icon: 'mdi-format-list-numbered',
        to: '/orders'
      },
      {
        text: 'Usuarios',
        icon: 'mdi-account-multiple',
        to: '/users',
        items: [
          {
            text: 'Lista de Usuarios',
            to: '/users',
            icon: 'mdi-account-multiple'
          },
          { text: 'Roles', to: '/roles', icon: 'mdi-security' }
        ]
      },
      { text: 'Productos', icon: 'mdi-store', to: '/products' },
      { text: 'Clientes', icon: 'mdi-account-box-multiple', to: '/customers' },
      { text: 'Almacenes', icon: 'mdi-storefront', to: '/stores' },
      { text: 'Cotizaciones', icon: 'mdi-format-list-bulleted', to: '/quotes' },
      { text: 'Transferencias', icon: 'mdi-swap-horizontal', to: '/transfers' },
      { text: 'Ventas', icon: 'mdi-cash-multiple', to: '/sales' },
      { text: 'Libreria', icon: 'mdi-image-multiple', to: '/library' },
      { text: 'Banners', icon: 'mdi-image-area', to: '/banners' },
      { text: 'Paginas', icon: 'mdi-book', to: '/pages' }
    ]
  }),
  components: { SubCategory, Search },
  computed: {
    ...mapState('Currency', ['currency', 'typeChange']),
    ...mapState('auth', ['isLogged', 'role', 'user']),
    ...mapState('Cart', ['cart']),
    ...mapState('Categories', ['categories', 'category']),
    ...mapState('Menu', ['menu']),

    ...mapMutations('Categories', ['setCategories', 'setCategory']),
    ...mapGetters('Cart', ['totalPrice', 'totalQuantity']),
    ...mapMutations('Currency', ['setType'])
  },

  watch: {
    // search(val) {
    //   this.$store.commit("setSearch", val);
    // },
    typeChange(val) {
      let isMxn = val == 'mxn' ? true : false;

      return (this.type = isMxn);
    },

    dialogCategory(val) {
      val || this.closeDialogCategory();
    }
  },

  created() {
    if (this.role != 'admin' && this.role != 'employee') {
      this.items = [{ text: 'inicio', icon: 'mdi-home', to: '/' }];
      this._getMenu();
      this._getCategories();
    }
    if (this.role == 'user') {
      this.items = [
        { text: 'inicio', icon: 'mdi-home', to: '/' },
        {
          text: 'Mis Ordenes',
          icon: 'mdi-format-list-numbered',
          to: '/my-orders'
        },
        {
          text: 'Datos de envío',
          icon: 'mdi-format-list-numbered',
          to: '/info'
        }
      ];
    } else if (this.role == 'employee') {
      this.items = [
        { text: 'Dashboard', icon: 'mdi-view-dashboard', to: '/dashboard' },
        { text: 'Ventas', icon: 'mdi-cash-multiple', to: '/sales' },
        { text: 'Almacén', icon: 'mdi-storefront', to: '/store' },
        {
          text: 'Transferencias',
          icon: 'mdi-swap-horizontal',
          to: '/my-store/transfers'
        },
        { text: 'Productos', icon: 'mdi-store', to: '/products' }
      ];
    }

    this.type = this.typeChange == 'mxn' ? true : false;
  },

  methods: {
    ...mapActions('Currency', ['changeType']),
    ...mapActions('Categories', ['getCategories']),
    ...mapActions('auth', ['closeSession']),
    ...mapActions('Cart', ['saveInCart']),
    ...mapActions('Menu', ['getMenu']),

    _changeType() {
      this.changeType(this.type ? 'mxn' : 'usd');
    },

    logOut() {
      this.closeSession();
      this.$cookies.remove('token');
    },

    close() {
      this.dialog = !this.dialog;
    },

    goBack() {
      // this.$router.go(-1);
      if (this.$route.name == 'home') {
        window.location.reload();
      }

      this.$router.replace('/');
    },

    goToCategory(item) {
      const categoryPrincipal = this.itemMenu;
      const categorySecondary = item;

      this.$router.push(
        `/categories/${categoryPrincipal.name
          .replace(/ /g, '_')
          .replace(/\//g, '&')}/${categorySecondary.name
          .replace(/ /g, '_')
          .replace(/\//g, '&')}`
      );

      this.menuDialog = false;
    },

    navigate(cat, subCat) {
      let result = '';
      const { subcategory } = this.$route.params;
      if (!subCat) {
        result = `/category/${cat.nombre
          .replace(/ /g, '_')
          .replace(/\//g, '&')}`;
      } else {
        result = `/categories/${cat}/${subCat}`;
        if (subcategory == subCat) {
          return;
        }
      }
      this.$router.push(result);
      this.dialogCategory = false;
      // this.$store.commit("setCategoryId", item.id);
    },

    async _getCategories() {
      await this.getCategories();
    },

    async _getMenu() {
      await this.getMenu();
      const isActived = this.menu.filter((item) => item.isActive == true);
      if (isActived.length > 0) {
        this.isMenuPersonalized = true;
      }
    },

    openDilaogCategory(item) {
      this.categorySelected = Object.assign({}, item);
      this.dialogCategory = !this.dialogCategory;
    },

    closeDialogCategory() {
      this.dialogCategory = false;
      // dialogCategory
      this.$nextTick(() => {
        this.categorySelected = Object.assign({}, {});
      });
    },

    openDialogMenu(item) {
      if (!item.categoryId) {
        window.location.href = item.url;
        return;
      }
      this.itemMenu = Object.assign({}, item);
      this.menuDialog = true;
    },

    addProduct(item) {
      let product = this.cart.find((product) => product._id == item._id);

      if (!product) {
        return;
      }
      product.quantity++;
      product.total = product.quantity * product.precios.precio_1;
      // if (product.precios.precio_descuento) {
      //   product.total = product.quantity * product.precios.precio_descuento;
      // } else {
      //   product.total = product.quantity * product.precios.precio_1;
      // }

      this.saveInCart(this.cart);
    },

    removeCart() {
      let cart = [];
      this.saveInCart(cart);
    },

    removeProduct(item) {
      let product = this.cart.find((pr) => pr._id == item._id);
      if (!product) {
        return;
      }

      product.quantity--;
      product.total = product.quantity * product.precios.precio_1;
      // if (product.precios.precio_descuento) {
      //   product.total = product.quantity * product.precios.precio_descuento;
      // } else {
      //   product.total = product.quantity * product.precios.precio_1;
      // }

      if (item.quantity == 0) {
        let filter = this.cart.filter((pr) => pr._id !== item._id);
        this.saveInCart(filter);
        return;
      }

      this.saveInCart(this.cart);
    }
  }
};
</script>

<style scoped>
.v-toolbar__content {
  height: auto !important;
}
</style>
